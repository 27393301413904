<template>
  <v-col cols="12" sm="10" md="8" lg="6" xl="6">
    <v-card flat>
      <v-card-title>
        <v-row align="center" justify="center">
          <h3>{{ $t('views.constants.updatePassword') }}</h3>
        </v-row>
      </v-card-title>
      <v-card-subtitle>
        <v-row align="center" justify="center">
          <h4>{{ $t('views.constants.toContinue') }}</h4>
        </v-row>
      </v-card-subtitle>
      <v-card-text>
        <app-form :fields="fields" :submit-button="submitButton" disable-cancel-button @submitted="handleSubmit" />
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

export default {
  name: 'SetPassword',
  data() {
    return {
      fields: {
        password: {
          type: 'password',
          key: 'password',
          value: '',
          label: this.$t('models.user.attributes.password'),
          rules: [{
            name: 'required'
          }, {
            name: 'min',
            length: 8
          }],
          classes: 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-0',
          serverErrors: []
        },
        passwordConfirmation: {
          type: 'password',
          key: 'passwordConfirmation',
          value: '',
          label: this.$t('models.user.attributes.confirmPassword'),
          rules: [{
            name: 'required'
          }, {
            name: 'min',
            length: 8
          }],
          classes: 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-0',
          serverErrors: []
        }
      },
      submitButton: {
        label: this.$t('actions.setPassword'),
        block: true
      }
    };
  },
  computed: mapGetters(['currentUser']),
  methods: {
    ...mapActions(['updateUserPassword']),
    handleSubmit(data) {
      this.updateUserPassword({...data, id: this.currentUser.id}).then((response) => {
        if (!this.successCode(response.status)) {
          this.displayInlineServerErrors(this.fields, response.errors);
        }
      });
    }
  }
};
</script>
